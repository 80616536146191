
import React, {useState,useEffect,useContext} from 'react'
import { Card, CardContent,useTheme,Typography,CardActions,Button } from '@mui/material';
import { useNavigate,useLocation } from 'react-router';
import BudgetEvenLogo from '../../../landingpage/landingimages/budgeteven.svg'
import { AltDataContext } from 'contexts/AltDataContext';
import { refreshFinAccountsHelper, uploadAccountsHelper } from 'utils/AccountMethods';


export default function BudgetRecapAfterScan() {

    
  const {state,dispatch} = useContext(AltDataContext)
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const firstText = location.state.sentenceOne
    const secondText = location.state.sentenceTwo
    const thirdText = location.state.sentenceThree
    const fourthText = location.state.sentenceFour


    const [currentStep, setCurrentStep] = useState(1)
      


    useEffect(() => {
      callRefresh()
    },[])

    async function callRefresh (){
    let accounts = await refreshFinAccountsHelper(state.userData.finCustomerId);
      await uploadAccountsHelper(accounts)
    }

    function updatedHandleNext(){
        if(currentStep === 1){
          if(secondText !== 'nil'){
            setCurrentStep(2)
          }
          else {
            if(thirdText !== 'nil'){
              setCurrentStep(3)
            }
            else {
              if(fourthText !== 'nil'){
                setCurrentStep(4)
              }
              else {
                navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
              }
            }
          }
        }




        if(currentStep === 2){
       
            if(thirdText !== 'nil'){
              setCurrentStep(3)
            }
            else {
              if(fourthText !== 'nil'){
                setCurrentStep(4)
              }
              else {
                navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
              }
            }
          
        }



        if(currentStep === 3){
       
          
            if(fourthText !== 'nil'){
              setCurrentStep(4)
            }
            else {
                navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
            }
          
        
      }

      if(currentStep === 4){
       
        
        navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
        
      
    
  }





}

      //await refreshFinAccountsHelper(state.userData.finCustomerId);

        return (

            <div className="center-container">
          <Card sx={{ minWidth: 400 }}>
      <CardContent>
      <img style={{alignSelf:'center',width:200}} src={BudgetEvenLogo}  alt="Logo" />
        <Typography variant="h5" component="div">
        {currentStep === 1 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}} component="div">{firstText}</Typography> : null}
      {currentStep === 2 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}}  component="div">{secondText}</Typography> : null}
      {currentStep === 3 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}}  component="div">{thirdText}</Typography> : null}
      {currentStep === 4 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}}  component="div">{fourthText}</Typography> : null}
        </Typography>
       
      </CardContent>
      <CardActions>
        <Button style={{marginLeft:'auto'}} onClick={() => updatedHandleNext()} size="small">Next</Button>
      </CardActions>
    </Card>
    </div>
          )


}