import BlogToolBar from '../components/BlogToolBar';
import { Box,Container,useMediaQuery,styled,Typography,Grid} from '@mui/material';
import BottomSpacer from 'uicomponents/BottomSpacer';
import React,{useEffect} from 'react'
import ShareButtons from '../components/ShareButtons';
import { Small } from 'uicomponents/Typography';



export default function TarrifsAndYourBudget() {


    const isSmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const downMd = useMediaQuery(theme => theme.breakpoints.down("md"));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up("md"))
    
    const Wrapper = styled(Box)(() => ({
      backgroundImage: "url('/static/landing-page/blogpagebackground.svg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "repeat",
      position:'absolute',
      top:0,
      right:0,
      width:'100%',
      height:'100%'
    }));

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])

    function determineMaxWidth(){
      if(isSmall){
        return 500
      }
      else if(downMd){
        return 500
      }
      else if(isLarge){
        return 800
      }
      else {
        return 800
      }
      
    }


 
  return (
<div>
    <Wrapper>
    <BlogToolBar />

    <Grid container justifyContent='center' display='flex' flexDirection='column' alignContent='center' alignItems='center'> 
    <Grid item>
    <Typography variant='h3' style={{textAlign:"center",fontWeight:'bold',color:'#1A77CD',marginTop:100}}>Tariffs and Your Budget</Typography>
    </Grid>
  
      <Grid item mt={2} style={{textAlign:'center'}}>
          <Small style={{textAlign:'center'}} justifyContent='center'  mb={3} fontSize={16} fontWeight={600} color="text.disabled">
          How Tariffs on Mexico and Canada Could Impact Your Monthly Budget
          </Small>
      </Grid>
    </Grid>

<Container style={{maxWidth:determineMaxWidth()}}>


<div>
<body style={{backgroundColor:'white'}}>

    
    <h2>Introduction</h2>
    <p>The U.S. economy is deeply interconnected with its North American neighbors—Mexico and Canada. Many goods that Americans rely on daily, from groceries to automobiles, are sourced from these countries. When tariffs are imposed on imports from Mexico and Canada, the costs don’t just affect businesses; they trickle down to the average American’s budget. Let’s explore how these tariffs could impact your monthly expenses.</p>
    
    <h2>1. Grocery Prices May Rise</h2>
    <p>A significant portion of fresh produce in the U.S. comes from Mexico, including avocados, tomatoes, and berries. Tariffs increase the cost for importers, who then pass those costs to consumers. If the price of produce rises by 10-20%, a household that spends $500 per month on groceries could see an increase of $50-$100 in their grocery bill.</p>
    
    <h2>2. Gas Prices Could Fluctuate</h2>
    <p>Mexico and Canada are major energy trade partners with the U.S., supplying crude oil and refined petroleum. Tariffs could disrupt this trade, leading to potential supply chain issues and increased fuel prices. If gas prices rise by 10-15 cents per gallon, that could mean an additional $10-$20 per month for an average commuter.</p>
    
    <h2>3. Higher Costs for Automobiles and Repairs</h2>
    <p>The North American auto industry is highly integrated, with car parts crossing the U.S.-Mexico and U.S.-Canada borders multiple times before a final vehicle is assembled. Tariffs on auto parts would drive up manufacturing costs, leading to higher prices for new cars. Even if you’re not in the market for a new vehicle, parts for repairs and maintenance—such as tires, batteries, and sensors—could become more expensive, increasing monthly vehicle expenses by $20-$50.</p>
    
    <h2>4. Consumer Goods and Electronics Could Become More Expensive</h2>
    <p>From appliances to electronics, many products contain components sourced from Mexico and Canada. If manufacturers face higher costs, they may pass them on to consumers. This could mean pricier household goods, increasing your monthly discretionary spending by 5-10%.</p>
    
    <h2>5. Job Market and Wages Could Be Affected</h2>
    <p>Tariffs can lead to retaliatory measures from trade partners, reducing U.S. exports and potentially leading to job losses in industries that rely on international trade. If businesses struggle, wages may stagnate, bonuses may decrease, and layoffs could increase, affecting household incomes.</p>
    
    <h2>Conclusion</h2>
    <p>While tariffs on Mexico and Canada may aim to protect domestic industries, they can have unintended financial consequences for everyday Americans. Higher grocery bills, gas prices, auto expenses, and consumer goods costs can add up, squeezing household budgets. Staying informed about trade policies and their economic impact can help families prepare and adjust their spending habits accordingly.</p>
</body>

</div>
<div style={{marginTop:25,fontWeight:500,fontStyle:'italic'}}>
            -Patrick Lysaght, Founder
            <div style={{marginTop:10}}>
            <ShareButtons url='https://www.budgeteven.com/learn/tarrifsandyourbudget' title='Tarrifs and Your Budget' />
            </div>
          
      </div>
        

         
        
    </Container>
  
    <BottomSpacer />
</Wrapper>

    </div>
  

 
   
  )
}