import { collection, getFirestore, getDocs, where,query,writeBatch,doc,setDoc,updateDoc,increment, addDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { uploadError } from "./FirebaseAnalyticsMethods";
import { getTodaysEpoch,getMonthAndYearStringFromEpoch, getCurrentMonthAndYearString } from "./Helpers";
import { v4 as uuidv4 } from 'uuid';
import { getFunctions,httpsCallable } from "firebase/functions";


const db = getFirestore();
const functions = getFunctions();

export async function deleteTransactionHelper(theId){
  try{

    let docRef = doc(db,'EuklesUsersData', getAuth().currentUser.uid, 'Transactions',String(theId));
    await updateDoc(docRef,{podName:'Deleted', trackingStatus:'Deleted', userLineItemDescription:'Deleted'})
  }catch(e){
    uploadError(e.message,'TransactionMethods.js, deletTransactionHelper')
  }

}

export async function deleteNewTransactionsHelper(transactions){
  try{

    const batch = writeBatch(db)
    let todayEpoch = getTodaysEpoch()
    let currentMonth = getCurrentMonthAndYearString();
    for(let i = 0; i < transactions.length; i++){
    
      let docRef = doc(db,'EuklesUsersData', getAuth().currentUser.uid, 'Transactions',String(transactions[i].docId));
      batch.update(docRef,{podName:'Deleted',trackingEpoch:todayEpoch, trackingStatus:'Deleted', userLineItemDescription:'Deleted',trackingMonthYear:currentMonth})
      //await updateDoc(docRef,{podName:'Deleted', trackingStatus:'Deleted', userLineItemDescription:'Deleted'})
    }
   let batchResult = await batch.commit().then(() => {
      return true;
    }).catch(() => {
      return false;
    })
    
    return batchResult

  }catch(e){
    uploadError(e.message,'TransactionMethods.js, deletTransactionHelper')
  }

}

export async function getAlreadyTrackedTransactionsForComparison(beginningEpoch){
  try{
    let collectionRef = collection(db,'EuklesUsersData',getAuth().currentUser.uid,'Transactions')
    let q = query(collectionRef,where("trackingEpoch",">=",beginningEpoch))
    let trackedTransactions = await getDocs(q).then(querySnapshot => {
        let localArray = []
        querySnapshot.forEach(doc => {
            localArray.push({id: doc.id})
        })
        return localArray
    }).catch(e => {
        return []
    })
    return trackedTransactions;
  }catch(e){
      uploadError(e.message,'TransactionMethos.js, getAlreadyTrackedTransactionsForComparison')
  }


}

export async function handleAddSingleTransactionHelper(transactionObject){
  try{
    // let date = new Date(transactionDate).getTime()/1000;

    // if(isNaN(date)){
    //   date = getTodaysEpoch();
    // }
  


    // let year = monthYear.slice(-4)

    // let trackingEpoch = getTodaysEpoch();
    
    // let theAmount = parseFloat(transactionAmount)
    // let randomId = uuidv4();

    // let transactionObject = {id: randomId, amount: theAmount, accountId: 'Manual',  status: 'Tracked',
    //    categorization:{normalizedPayeeName: description,bestRepresentation: description,category: podName},
    //   description: description,memo: description, postedDate: date, transactionDate: date,createdDate: date, 
    //   trackingYear: year,trackingEpoch: trackingEpoch,normalizedPayeeName: description,category: podName,bestRepresentation: description, trackingStatus: 'Tracked', 
    //   trackingMonthYear:monthYear,podName: podName, userLineItemDescription: description}


      let docRef = doc(db, 'EuklesUsersData',getAuth().currentUser.uid, 'Transactions', transactionObject.id)
      let uploadTransaction = await setDoc(docRef,transactionObject).then(() => {
        return true;
      }).catch(() => {
        return false;
      })
      return uploadTransaction;
  }catch(e){
    uploadError(e.message,'TransactionMethods.js,handleAddSingleTransactionHelper')
  }
}

export function handleRunTrackingRules(trackingRules,rawTransactionArray){
  try{
      let submitTransactionsArray =[]
      let uncategorizedArray = []
    
      let todayEpoch = getTodaysEpoch();
      const userUid = getAuth().currentUser.uid;
      for(let i = 0; i < rawTransactionArray.length; i++){  
        let localRules = trackingRules
        let transaction = rawTransactionArray[i]
        let transactionMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
        let transCategory = ''
        let transBestRep = ''
        let transPayee = ''

        if(typeof transaction.categorization !== 'undefined'){
          if(typeof transaction.categorization.category !== 'undefined'){
            transCategory  = transaction.categorization.category
          }
          if(typeof transaction.categorization.bestRepresentation !== 'undefined'){
            transBestRep = transaction.categorization.bestRepresentation
          }
          if(typeof transaction.categorization.normalizedPayeeName !== 'undefined'){
            transPayee = transaction.categorization.normalizedPayeeName
          }
        }


        let matchingCategoryObjects = localRules.filter(obj => obj.transactionCategory === transCategory)

        if(matchingCategoryObjects.length >= 1){

          let matchingBestRepIndex = matchingCategoryObjects.findIndex(obj => obj.transactionBestRep === transBestRep)

          if(matchingBestRepIndex === -1){
            let matchingPayeeIndex = matchingCategoryObjects.findIndex(obj => obj.transactionPayeeName === transPayee)

                  if(matchingPayeeIndex !== -1){

                    //totaling up
                   
                    // let matchingExistingIndex = updateSpentValuesArray.findIndex(obj => obj.podName === matchingCategoryObjects[matchingPayeeIndex].thenTrackToPod && obj.lineItem === matchingCategoryObjects[matchingPayeeIndex].andLineItem && obj.monthYear === transactionMonthAndYear)
                    // if(matchingExistingIndex === -1){
                    //     updateSpentValuesArray.push({amount:transaction.amount,monthYear:transactionMonthAndYear, podName: matchingCategoryObjects[matchingPayeeIndex].thenTrackToPod, lineItem:matchingCategoryObjects[matchingPayeeIndex].andLineItem })
                    // }
                    // else {
                    //     updateSpentValuesArray[matchingExistingIndex].amount = parseFloat(updateSpentValuesArray[matchingExistingIndex].amount) + parseFloat(transaction.amount)
                    // }


                    submitTransactionsArray.push({transaction:transaction,transactionMonthAndYear: transactionMonthAndYear, podName: matchingCategoryObjects[matchingPayeeIndex].thenTrackToPod, lineItem: matchingCategoryObjects[matchingPayeeIndex].andLineItem,
                    todayEpoch: todayEpoch, userUid: userUid })
                  }
                  else {
                    uncategorizedArray.push(transaction)
                  }
          }
          else {
     
            // let matchingExistingRepIndex = updateSpentValuesArray.findIndex(obj => obj.podName === matchingCategoryObjects[matchingBestRepIndex].thenTrackToPod && obj.lineItem === matchingCategoryObjects[matchingBestRepIndex].andLineItem && obj.monthYear === transactionMonthAndYear)
            // if(matchingExistingRepIndex === -1){
            //     updateSpentValuesArray.push({amount:transaction.amount,monthYear:transactionMonthAndYear, podName:  matchingCategoryObjects[matchingBestRepIndex].thenTrackToPod, lineItem: matchingCategoryObjects[matchingBestRepIndex].andLineItem})
            // }
            // else {
            //     updateSpentValuesArray[matchingExistingRepIndex].amount = parseFloat(updateSpentValuesArray[matchingExistingRepIndex].amount) + parseFloat(transaction.amount)
            // }

            submitTransactionsArray.push({transaction:transaction,transactionMonthAndYear: transactionMonthAndYear, podName: matchingCategoryObjects[matchingBestRepIndex].thenTrackToPod, lineItem: matchingCategoryObjects[matchingBestRepIndex].andLineItem,
              todayEpoch: todayEpoch, userUid: userUid })
         
          }
        }
        else {
          uncategorizedArray.push(transaction)
        }
      }
        
        return {submitTransactionsArray,uncategorizedArray}
  }catch(e){
      uploadError(e.message,'TransactionMethods.js, handleRunTrackingRules')
  }

}

export async function handleScanForNewTransactionsHelper(finCustomerId,beginningEpoch,endingEpoch,budgetMonthsArray){
  try{

      let getTransactions = httpsCallable(functions,'getMonthToDateTransactions');
      let rawTransactionArray = await getTransactions({customerId:finCustomerId,  beginningEpoch: beginningEpoch, endingEpoch: endingEpoch}).then((result) => {
             return result.data.newTransactionArray
         }).catch((error) => {
             return []
         })
  
         if(rawTransactionArray.length >= 1){
          rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Investment Income')
          rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Buy Other')
          rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Buy Mutual Fund')
          rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Sell Other')
          rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'RETIREMENT ACCT FEE')
          rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Buy Stock')
         }
         let filteredTransactions = []
         rawTransactionArray.forEach(async transaction => {
          let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
          let existingIndex = budgetMonthsArray.findIndex(obj => obj === trackingMonthAndYear)
          if(existingIndex !== -1){
              filteredTransactions.push(transaction)
          }
      });
     return rawTransactionArray;
  }catch(e){
      uploadError(e.message,'TransactionMethos.js, handleScanForNewTransactionsHelper')
  }

}


export async function getDeletedTransactionsHelper(selectedMonth){
    try{
        let uid = getAuth().currentUser.uid
        const db = getFirestore();
        let queryRef = collection(db,'EuklesUsersData', uid, 'Transactions');
        const q = query(queryRef,where('trackingMonthYear', '==', selectedMonth),where('trackingStatus','==','Deleted'))
      
          let transactions = await getDocs(q).then((querySnapshot) => {
            let localArray = [];
            querySnapshot.forEach((doc) => {
                localArray.push({id: doc.id, amount: doc.data().amount, transactionDate: doc.data().transactionDate,
                  description: doc.data().description, normalizedPayeeName: doc.data().normalizedPayeeName })
            })
            return localArray;
          }).catch((e) => {
            return [];
          })
          
         
          transactions =  transactions.sort((a,b) => a.transactionDate < b.transactionDate ? 1 : -1)
          return transactions
    }catch(e){
        uploadError(e.message,"function is getDeletedTransactionsHelper, TransactionMethods.js")
    }

}

export async function getFinTransactionsSingleAccount(finCustomerId,accountId,startDate,todayEpoch){

  try{

        let retrieveFinTransactions = httpsCallable(functions,'getFinTransactionsSingleAccount');
        let uid = getAuth().currentUser.uid;

        let newTransactionArray = await retrieveFinTransactions({customerId: finCustomerId, accountId: accountId, uid: uid, monthStartEpoch: startDate, monthEndEpoch: todayEpoch}).then(function(result){
          return result.data.newTransactionArray;
          }).catch(function(error){
      
              return false;
          });

          return newTransactionArray;


  }catch(e){
    uploadError(e.message,'TransactionMethods.js, getFinTransactionsSingleAccount')
  }

}

export async function getNewTransactionsHelper(selectedMonth){

  try{
   let uid = getAuth().currentUser.uid
   const db = getFirestore();
   let queryRef = collection(db,'EuklesUsersData', uid, 'Transactions');
   const q = query(queryRef,where('trackingStatus','==','New'))
 
     let transactions = await getDocs(q).then((querySnapshot) => {
       let localArray = [];
       querySnapshot.forEach((doc) => {
         let normalizedPayeeName = ''
           let category = ''
           let bestRepresentation = ''
           if(typeof doc.data().categorization !== 'undefined'){
               normalizedPayeeName = doc.data().categorization.normalizedPayeeName
               category = doc.data().categorization.category
               bestRepresentation = doc.data().categorization.bestRepresentation
           }
 
           let obj = {description: doc.data().description, userLineItemDescription: doc.data().userLineItemDescription,categorization:{
            normalizedPayeeName: normalizedPayeeName,
            category: category,
            bestRepresentation: bestRepresentation
           },
             docId: doc.id,trackingMonthYear: doc.data().trackingMonthYear,normalizedPayeeName: normalizedPayeeName,
             category: category, bestRepresentation: bestRepresentation,checked:false,transactionId:doc.id,
             transactionDate: doc.data().transactionDate, podName: doc.data().podName, amount: doc.data().amount}
             localArray.push(obj)
       })
       return localArray;
     }).catch((e) => {
       return [];
     })
 
 
   
    transactions =  transactions.sort((a,b) => a.transactionDate < b.transactionDate ? 1 : -1)
    return transactions;
  }catch(e){
   uploadError(e.message,"function is getTrackedTransactionsHelper, TransactionMethods.js")
  }
 
}

export async function getRecurringTransactionsHelper(finCustomerId){
try{

  let getTransactions = httpsCallable(functions,'getFinTransactionsForRecurring');
  let transactions = await getTransactions({customerId: finCustomerId}).then(function(result) {
    return {error: false, transactionArray: result.data.newTransactionArray} 
  }).catch(function(error){
    return {error: true, transactionArray: []}
  })

  return transactions;

}catch(e){

}
}

export async function getAllSavingsTransctions(){

  try{

      let userUid = getAuth().currentUser.uid;

        const transRef = collection(db, 'EuklesUsersData', userUid, 'Transactions')
        const q = query(transRef, where('podName', '==', 'Savings'))

        let transactionsArray = await getDocs(q).then((querySnapshot) => {
            let localArray = [];
            if (querySnapshot.size > 0) {

                querySnapshot.forEach((doc) => {

                    let trackingMonth = ''
                    try{
                     let full = doc.data().trackingMonthYear
                     trackingMonth = full.slice(0,full.length - 4).trim()
                  
                    }
                    catch(e){
     
                    }

                    let obj = {
                        trackingMonthYear: doc.data().trackingMonthYear, trackingMonth: trackingMonth,
                        podName: doc.data().podName, amount: doc.data().amount
                    }
                    localArray.push(obj);


                })
            }
            return localArray;
        }).catch(() => {
          return []
        })

        return transactionsArray

  }catch(e){
    uploadError(e.message,'TransactionMethods.js, getAllSavingsTransations')
  }
}

export async function getTransactionsByLineItem(podName,description,month){

  const userUid = getAuth().currentUser.uid;
  let queryRef = collection(db,'EuklesUsersData', userUid, 'Transactions');
  const q = query(queryRef,where('trackingMonthYear', '==', month),where('podName','==',podName), where('userLineItemDescription','==',description))

    let transactions = await getDocs(q).then((querySnapshot) => {
      let localArray = [];
      querySnapshot.forEach((doc) => {
        let normalizedPayeeName = ''
          let category = ''
          let bestRepresentation = ''
          if(doc.data().trackingStatus === 'Tracked'){

          if(typeof doc.data().categorization !== 'undefined'){
              normalizedPayeeName = doc.data().categorization.normalizedPayeeName
              category = doc.data().categorization.category
              bestRepresentation = doc.data().categorization.bestRepresentation
          }

          let obj = {description: doc.data().description, userLineItemDescription: doc.data().userLineItemDescription,
            docId: doc.id,trackingMonthYear: doc.data().trackingMonthYear,normalizedPayeeName: normalizedPayeeName,
            category: category, bestRepresentation: bestRepresentation,
            transactionDate: doc.data().transactionDate, podName: doc.data().podName, amount: doc.data().amount}
            localArray.push(obj)
          }
      })
      return localArray;
    }).catch((e) => {
      return [];
    })
    return transactions
}

export async function getTransactionsForAnalysis(month){

  try{
    let userUid = getAuth().currentUser.uid;
    let queryString = 'trackingMonthYear'
    if(month.length === 4){
        queryString = 'trackingYear'
    }
    const transRef = collection(db,'EuklesUsersData',userUid,'Transactions')
    const q = query(transRef,where(queryString,'==',month))

    let transactionsArray = await getDocs(q).then((querySnapshot) => {
      let localArray = [];
     if(querySnapshot.size > 0){
         querySnapshot.forEach((doc) => {
          if(doc.data().podName !== 'Income' && doc.data().podName !== 'income' && doc.data().podName !== 'Deleted' && doc.data().podName !== 'Delete' && doc.data().podName !== 'delete'){
            let obj = {description: doc.data().description, podName: doc.data().podName, amount: doc.data().amount,lineItemDescription: doc.data().userLineItemDescription}
            localArray.push(obj);
          }
        
         })
     }
     return localArray;
 }).catch(() => {
  return []
 })
return transactionsArray
  }catch(e){
uploadError(e.message,'getTransactionsForAnalysis, TransactionMethods.js')
  }
}


export async function getTrackedTransactionsHelper(selectedMonth){

   try{
    let uid = getAuth().currentUser.uid
    const db = getFirestore();
    let queryRef = collection(db,'EuklesUsersData', uid, 'Transactions');
    const q = query(queryRef,where('trackingMonthYear', '==', selectedMonth),where('trackingStatus','==','Tracked'))
  
      let transactions = await getDocs(q).then((querySnapshot) => {
        let localArray = [];
        querySnapshot.forEach((doc) => {
          let normalizedPayeeName = ''
            let category = ''
            let bestRepresentation = ''
            if(typeof doc.data().categorization !== 'undefined'){
                normalizedPayeeName = doc.data().categorization.normalizedPayeeName
                category = doc.data().categorization.category
                bestRepresentation = doc.data().categorization.bestRepresentation
            }
  
            let obj = {description: doc.data().description, userLineItemDescription: doc.data().userLineItemDescription,
              docId: doc.id,trackingMonthYear: doc.data().trackingMonthYear,normalizedPayeeName: normalizedPayeeName,
              category: category, bestRepresentation: bestRepresentation,
              transactionDate: doc.data().transactionDate, podName: doc.data().podName, amount: doc.data().amount}
              localArray.push(obj)
        })
        return localArray;
      }).catch((e) => {
        return [];
      })
  
  
    
     transactions =  transactions.sort((a,b) => a.transactionDate < b.transactionDate ? 1 : -1)
     return transactions;
   }catch(e){
    uploadError(e.message,"function is getTrackedTransactionsHelper, TransactionMethods.js")
   }
  
}

export async function reclassifyTransactionHelper(transaction,newGroupName,newLineItemDescription){

  try{
    let concat = newLineItemDescription.concat('.spentValue')

    let docRefNew = doc(db,'EuklesUsersData',getAuth().currentUser.uid,transaction.trackingMonthYear,newGroupName)
    await updateDoc(docRefNew,{
      [concat]: increment(transaction.amount)
    })

    let transactionRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'Transactions',String(transaction.docId))
    await updateDoc(transactionRef,{
      userLineItemDescription: newLineItemDescription,
      podName: newGroupName
    })

    let oldPodName = transaction.podName
    let oldLineItem = transaction.userLineItemDescription
    let monthYear = transaction.trackingMonthYear
    let transactionAmount = transaction.amount;

    if(transactionAmount > 0){
      transactionAmount = Math.abs(transactionAmount) * -1
  }
  else {
      transactionAmount = Math.abs(transactionAmount)
  }


  let oldLineItemDescription = oldLineItem.concat('.spentValue')
  let oldDoc = doc(db,'EuklesUsersData',getAuth().currentUser.uid,monthYear,oldPodName)
   await updateDoc(oldDoc,{[oldLineItemDescription]: increment(transactionAmount)})

  return true;
  }catch(e){

  }
}

export async function updateTransactionsLineItemDescriptions(month,podName,oldDescription,scrubbed){
  try{
    let transactionsRef = collection(db,'EuklesUsersData',getAuth().currentUser.uid,'Transactions')
    let transactionQuery = query(transactionsRef,where("trackingMonthYear","==",month),where("podName","==",podName),where("userLineItemDescription","==",oldDescription))
    let matchingTransactions = await getDocs(transactionQuery).then(querySnapshot => {
     let localArray = []
     querySnapshot.forEach(doc => {
       localArray.push(doc.id)
     })
     return localArray;
    }).catch(e => {
     return []
    })

    if(matchingTransactions.length >= 1){
      const batch = writeBatch(db);
      matchingTransactions.forEach(async docId => {
       let transRef = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'Transactions',String(docId))
       batch.update(transRef,{userLineItemDescription:scrubbed})
      })
  
      await batch.commit().then(() => {
        return true;
      }).catch(() => {
        return false;
      })
    }


    return true;

  }catch(e){
    uploadError(e.message,'TransactionMethods.js, updateTransactionsLineItemDescriptions')
  }
}

export async function uploadCategorizedTransactions(transactionsWithDetailsArray){

   try{

    let todayEpoch = getTodaysEpoch()
      const batch = writeBatch(db)
    for(let i = 0; i < transactionsWithDetailsArray.length; i++){
     let transaction = transactionsWithDetailsArray[i].transaction
  
     let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
     let podName = transactionsWithDetailsArray[i].podName
     let lineItemDescription = transactionsWithDetailsArray[i].lineItem
     let trackingStatus = podName === 'Delete' ? 'Deleted' : 'Tracked'
  
  
      let myObject = {...transaction,podName: podName, userLineItemDescription: lineItemDescription, trackingEpoch: todayEpoch,trackingStatus: trackingStatus, trackingMonthYear: trackingMonthAndYear}
     let transactionDoc = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'Transactions',String(transaction.id))
     batch.set(transactionDoc,myObject)

    }

   let b = await batch.commit().then(() => {
      return true;
    }).catch(() => {
      return false;
    })
    return b;
  
   }catch(e){
    uploadError(e.message,'TransactionMethods.js, uploadCategorizedTransaction')
   }

  
}

export async function uploadNewTransactionsHelper(transactions){

  try{
    const batch = writeBatch(db)
    for(let i = 0; i < transactions.length; i++){
      let transaction = transactions[i]
      let myObject = {...transaction, trackingStatus: 'New'}
      let transDoc = doc(db,'EuklesUsersData',getAuth().currentUser.uid,'Transactions',String(transaction.id))
      batch.set(transDoc,myObject)
  }

  let b = await batch.commit().then(() => {
    return true;
  }).catch(() => {
    return false;
  })

return b;
  }catch(e){
    uploadError(e.message,'TransactionMethods.js, uploadNewTransactionsHelper')
  }

}

export async function uploadNewRulesHelper(transactionsWithDetailsArray,podTitle,lineItemDescription){

  try{
      let filteredArray = []

            
      for(let i = 0; i < transactionsWithDetailsArray.length; i++){
          let transaction = transactionsWithDetailsArray[i]
          if(typeof transaction.categorization.category !== 'undefined' && typeof transaction.categorization.bestRepresentation !== 'undefined' && typeof transaction.categorization.normalizedPayeeName !== 'undefined'){
              if(i === 0){
                  filteredArray.push(transaction)
              }
              else {
   
                  let existingIndex = filteredArray.findIndex(obj => obj.categorization.category === transaction.categorization.category && obj.categorization.bestRepresentation === transaction.categorization.bestRepresentation &&  obj.categorization.normalizedPayeeName === transaction.categorization.normalizedPayeeName)
               
                  if(existingIndex === -1){
                    filteredArray.push(transaction)
                  }
              }
          }
      }

            
      const userUid = getAuth().currentUser.uid;
      for(let i = 0; i < filteredArray.length; i++){
          let transaction = filteredArray[i]
          let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
              transactionCategory: transaction.categorization.category,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
              let docRef = collection(db,'EuklesUsersData',userUid,'TrackingRules')
              await addDoc(docRef,trackingRule)
      }

      return true;
  }catch(e){
      uploadError(e.message,'TransactionMethods.js, uploadNewRulesHelper')
  }




}

export async function uploadTransactionsHelper(transactionsWithDetailsArray,podName,lineItemDescription){

  try{
      const userUid = getAuth().currentUser.uid;
      let todayEpoch = getTodaysEpoch()

       for(let i = 0; i < transactionsWithDetailsArray.length; i++){
   
        let transaction = transactionsWithDetailsArray[i]

        let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)

        let trackingStatus = podName === 'Delete' ? 'Deleted' : 'Tracked'
 
  
         let myObject = {...transaction,podName: podName, userLineItemDescription: lineItemDescription, trackingEpoch: todayEpoch,trackingStatus: trackingStatus, trackingMonthYear: trackingMonthAndYear}
         let docRef = doc(db,'EuklesUsersData',userUid,'Transactions',String(transaction.transactionId))
        await setDoc(docRef,myObject).then(res => {
         
            return true
        }).catch(e => {

            console.log('error ', transaction)
        })
       }
  }catch(e){
      uploadError(e.message,'TransactionMethods.js, uploadTransactionsHelper')
  }

}