import AccountLineItem from './AccountLineItem';
import { AltDataContext } from 'contexts/AltDataContext';
import BackDropLoading from 'utils/BackDropLoading';
import BottomSpacer from 'uicomponents/BottomSpacer';
import CenteredEllipsis from 'uicomponents/CenteredEllipsis';
import { checkForLogo, generateFixCustomUrl, getInvestmentAccountsFromFireStore, refreshFinAccountsHelper, retrieveBranding, retrieveFinicityUrl, uploadBranding } from 'utils/AccountMethods';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { getAuth } from 'firebase/auth';
import { getFirestore,doc,getDocs,collection,updateDoc,query, where } from 'firebase/firestore';
import { H2} from "uicomponents/Typography";
import NotPremiumCard from './NotPremiumCard';
import React,{useEffect, useState,useRef,useContext} from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import { Table,TableHead,TableRow,TableCell,TableBody,useTheme,Button,useMediaQuery,Box } from '@mui/material'
import toast from 'react-hot-toast';
import { TopAreaWrapper } from 'uicomponents/TopAreaWrapper';
import { useNavigate } from 'react-router';


const db = getFirestore();


export default function UpdatedAccounts() {

    const navigate = useNavigate();
    const theme = useTheme();
    const {state} = useContext(AltDataContext)
    const isDownMedium = useMediaQuery(theme.breakpoints.down('md'));

    const selectedAccountIdRef = useRef(null)
    const selectedAccountPositionRef = useRef(null)
    const [accountLineItemsArray, setAccountLineItemsArray] = useState([])
    const [loadingMessage,setLoadingMessage] = useState('Gathering your accounts...')
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const [showLoading,setShowLoading] = useState(true)


    useEffect(() => {
        augustUpdateGetAccounts();

    },[])

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])
    

      async function augustUpdateGetAccounts(){
        if(state.userData.isPremiumUser){
          let accounts = await getInvestmentAccountsFromFireStore();
          if(accounts.length >= 1){
            accounts = accounts.filter(obj => obj.isFinicity === true)
           sortAccounts(accounts)
     
          }
          else {
            setShowLoading(false);
          }
        
        }
        else {
          setShowLoading(false);
        }
      }


      async function getAccountsUpdated(){
        setShowLoading(true);
        setLoadingMessage('Refreshing your account data...')
          const userUid = getAuth().currentUser.uid;
          let finAccountsArray = await refreshFinAccountsHelper(state.userData.finCustomerId)
   
          let accountsRef = collection(db,'EuklesUsersData',userUid,'Accounts')
          let q = query(accountsRef,where("isCashFlowAccount","==",true))
          let cashFlowAccountId = await getDocs(q).then(querySnapshot => {
          
            let id = ''
              querySnapshot.forEach(doc => { 
                id = doc.id
              })
            
            return id
          }).catch(e => {
            return false
          })
    
         
    
          if(finAccountsArray === false || finAccountsArray.length === 0){
            //error

          }
          else {
            
            for(let i = 0; i < finAccountsArray.length; i++){
                if(finAccountsArray[i].id !== cashFlowAccountId){
                    let myObject = {...finAccountsArray[i], isFinicity:true}
                    let accountsDoc = doc(db,'EuklesUsersData',userUid,'Accounts',String(finAccountsArray[i].id))
                    await updateDoc(accountsDoc,myObject)
                    //await uploadBranding(finAccountsArray[i])
                }
                else {
                    let myObjectAlt = {...finAccountsArray[i], isFinicity:true, isCashFlowAccount:true}
                    let accountsDocRef = doc(db,'EuklesUsersData',userUid,'Accounts',String(finAccountsArray[i].id))
                    await updateDoc(accountsDocRef,myObjectAlt)
                   // await uploadBranding(finAccountsArray[i])
                }
             
          }
        }
    
      

    augustUpdateGetAccounts();
        
          }

          function handleDeleteAccountClick(account,position){

            //account.id
            selectedAccountIdRef.current = account.docId
            selectedAccountPositionRef.current = position
            setShowConfirmDelete(true);
          }


          async function handleDelete(){
            setShowConfirmDelete(false)
          setShowLoading(true);
          setLoadingMessage('Contacting Finicity...')
          let currentArray = accountLineItemsArray
          currentArray.splice(selectedAccountPositionRef.current,1)
          setAccountLineItemsArray(previousState => [...currentArray])

          let urlResult = await retrieveFinicityUrl(state.userData.finCustomerId)

          console.log('url result ', urlResult)


          navigate('../finicityaawb',{state:{urlToUse:urlResult.data.theUrl,finCustomerId:state.userData.finCustomerId}});
          setShowLoading(false);
          }

          async function handleFixInstitution(item){
  
            setShowLoading(true);
            let urlResult = await generateFixCustomUrl(state.userData.finCustomerId,item.institutionLoginId)
     
            if(urlResult.data.theUrl === 'error getting url' || urlResult.data.theUrl === null){
              toast.error('Budget Even has encountered an error')
              setShowLoading(false);
              return false;
            }
            else {
              let theUrl = String(urlResult.data.theUrl);
                navigate('../finicityaawb',{state:{urlToUse: theUrl,finCustomerId: state.userData.finCustomerId}});
              return true;
              
            }
          
          }


      async function handleTopPartClick(){
        navigate('../pricing',{state:{comingFrom:'budgethome'}});

       }

      async function sortAccounts(accounts){

        let assets = accounts.filter(obj => obj.balance >= 0.00)
        let liabilities = accounts.filter(obj => obj.balance < 0)
      
        let assetsGroupedByInstitution = []
        for(let i = 0; i < assets.length; i++){
          let existingIndex = assetsGroupedByInstitution.findIndex(obj => obj.institutionId === assets[i].institutionId)
          if(existingIndex === -1){
           let obj = {parentInstitution: assets[i].institutionId, logo: assets[i].logo, icon: assets[i].icon,tile: assets[i].tile, isFinicity: assets[i].isFinicity, institutionId: assets[i].institutionId, accountsArray:[assets[i]]}
           assetsGroupedByInstitution.push(obj)
          }
          else {
            assetsGroupedByInstitution[existingIndex].accountsArray.push(assets[i])
          }
        }
      
      
        let liabilitiesGroupedByInstitution = []
        for(let i = 0; i < liabilities.length; i++){
          let existingIndex = liabilitiesGroupedByInstitution.findIndex(obj => obj.institutionId === liabilities[i].institutionId)
          if(existingIndex === -1){
           let obj = {parentInstitution: liabilities[i].institutionId, logo: liabilities[i].logo, icon:liabilities[i].icon,tile:liabilities[i].tile, isFinicity: liabilities[i].isFinicity, institutionId: liabilities[i].institutionId, accountsArray:[liabilities[i]]}
           liabilitiesGroupedByInstitution.push(obj)
          }
          else {
            liabilitiesGroupedByInstitution[existingIndex].accountsArray.push(liabilities[i])
          }
        }


        for(let i = 0; i < assetsGroupedByInstitution.length; i++){
   
          if(assetsGroupedByInstitution[i].isFinicity){
            
            let logo = await checkForLogo(assetsGroupedByInstitution[i].institutionId)
        
            if(logo === 'no_doc' || logo === false){
               logo = await retrieveBranding(assetsGroupedByInstitution[i].institutionId)
        
              if(logo !== 'nil'){
                assetsGroupedByInstitution[i].logo = logo
      
                await uploadBranding(assetsGroupedByInstitution[i].institutionId,logo)
              }
            }
            else {
              assetsGroupedByInstitution[i].logo = logo
            }
            
          }
        }
  

        for(let i = 0; i < liabilitiesGroupedByInstitution.length; i++){
    
          if(liabilitiesGroupedByInstitution[i].isFinicity){
            
            let logo = await checkForLogo(liabilitiesGroupedByInstitution[i].institutionId)
            if(logo === 'no_doc' || logo === false){
              let logo = await retrieveBranding(liabilitiesGroupedByInstitution[i].institutionId)
              if(logo !== 'nil'){
                liabilitiesGroupedByInstitution[i].logo = logo
                await uploadBranding(liabilitiesGroupedByInstitution[i].institutionId,logo)
              }
            }
            else {
              liabilitiesGroupedByInstitution[i].logo = logo
            }
      
          }
        
        
        }
      


      
      let allAccounts = []
      assetsGroupedByInstitution.forEach(obj => {
        allAccounts.push(obj)
      })
      liabilitiesGroupedByInstitution.forEach(obj => {
        allAccounts.push(obj)
      })

      let lineItemsArray = []

      for(let i = 0; i < assetsGroupedByInstitution.length;i++){
        let accountsArray = assetsGroupedByInstitution[i].accountsArray
        for(let b = 0; b < accountsArray.length; b++){
          lineItemsArray.push({...accountsArray[b],logo:assetsGroupedByInstitution[i].logo, icon: assetsGroupedByInstitution[i].icon})
        }
      }

      for(let i = 0; i < liabilitiesGroupedByInstitution.length;i++){
        let accountsArray = liabilitiesGroupedByInstitution[i].accountsArray
        for(let b = 0; b < accountsArray.length; b++){
          lineItemsArray.push({...accountsArray[b],logo: liabilitiesGroupedByInstitution[i].logo, icon: liabilitiesGroupedByInstitution[i].icon})
        }
      }

      try{
        lineItemsArray.sort((a,b) => a.institutionId - b.institutionId)
      }catch(e){
        console.log(e)
      }

      setAccountLineItemsArray([...lineItemsArray])
      setShowLoading(false)
        
      }
  



      if(state.userData.isPremiumUser){
        return (
          
          <Box pt={2} pb={10} style={{marginLeft: 60}}>
          <TopAreaWrapper>
          <Box style={{padding:25}}>
                  <H2 style={{color: theme.palette.primary.dialogBackground}} paddingLeft={3} paddingBottom={1}>
                    Account details
                  </H2>
              </Box>

              {showConfirmDelete ? <ConfirmDeleteModal handleCancel={() => setShowConfirmDelete(false)} handleDelete={handleDelete} /> : null}
          </TopAreaWrapper>
          {showLoading ? <div style={{marginTop:200}}><CenteredEllipsis /></div> : 
          <> <Button onClick={() => getAccountsUpdated()} startIcon={<RefreshIcon />} style={{marginTop:20}}>Refresh Accounts</Button>
            <Table style={{width: isDownMedium ? '100%' : 'auto',   minWidth: isDownMedium ? 300 : 500, marginTop:5}}>
          <TableHead sx={{backgroundColor: theme.palette.primary.dialogBackground}}>
            <TableRow>
            <TableCell  style={{color:'white', fontWeight:'bold'}}>Institution</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Account</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Balance</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Last Update</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Due Date</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Last Payment Amount</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>View Transactions</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Update</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Remove</TableCell>
            </TableRow>
      
          </TableHead>
          <TableBody style={{backgroundColor:'white', }}>
          {accountLineItemsArray.map((item,i) => {
                          return(
                            <AccountLineItem key={i.toString()} position={i} theme={theme} item={item} navigate={navigate} handleDeleteAccountClick={handleDeleteAccountClick} handleFixInstitution={handleFixInstitution}/>
                          )
                      })}
          </TableBody>
          </Table>
          </> }
          <BottomSpacer />
         </Box>
        )
      }
      else {
        return (
          <NotPremiumCard handleTopPartClick={handleTopPartClick} />
        )
      }
    
 
   
   
}
